import t from "../services/GetTranslationValue"
import _ from "../services/NullIf"

class GetRoute {
  static removeAccents(str) {
    var accents =
      "ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ"
    var accentsOut =
      "AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg"

    str = str.split("")
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter)
      if (i != -1) {
        str[index] = accentsOut[i]
      }
    })

    return str
      .join("")
      .replace(/[^0-9a-zA-Z_\-/]/g, "")
      .toLowerCase()
  }


  static get(locale, translations, routeName, params, hasHash) {
    let localeAlias = 'en';
    if (locale === 'en')
      localeAlias = 'en';

    if (locale === 'enCA')
      localeAlias = 'ca';

    if (locale === 'enGB')
      localeAlias = 'uk'

    if (locale === 'fi')
      localeAlias = 'fi'

    const getBonusTypeUrl = (params, locale, translations)=> {
      if (params === 'ilman-talletusta' || params === 'freespins-no-deposit')
        return (localeAlias  + "/"
          + getT(translations, "freespins-url")
          + "/");

      if (params === 'no-account' && locale === 'fi')
        return (localeAlias  + "/"
        + "ilmanrekisteroitymista" + "/");

      return localeAlias  +
        "/" +
        params +
        "/";
    }

    const getT = (translations, name) => {
      return t.get(translations, name)
    }

    if (_.isNotNull(params)) {
      if (typeof params === typeof {}) params = ""
      params = this.removeAccents(params.replace(/ /g, "-"))
      const data = {
        // base urls
        //
        home: localeAlias + "/",
        blog:
          localeAlias  + "/" + getT(translations, "Blog Url") + "/" + params + "/",

        myAccount:
          localeAlias  + "/" + getT(translations, "My Account Url") + "/",

        emailVerification:
          localeAlias  + "/" + getT(translations, "Email Verification Url") + "/",

        casinoReviewLanding:
          localeAlias  + "/" + getT(translations, "casino-review-silo-url") + "/",

        providerFreespins:
          localeAlias  +
          "/"
          +  params + '-' + getT(translations, "freespins-slot") +
          "/",


        bonusCodeLanding:
          localeAlias  +
          "/" +
          getT(translations, "bonus-codes-landing"),

        gameProviderLanding:
          localeAlias  +
          "/" +
          getT(translations, "game-provider"),

        platformsLanding:
          localeAlias +
          "/" +
          getT(translations, "platforms"),

        bigWinsLanding:
          localeAlias +
          "/" +
          getT(translations, "big-wins"),

        casinoNewsLanding:
          localeAlias  + "/" + getT(translations, "casino-news-landing") + "/",
        generic: localeAlias  + "/" + params + "/",
        redirectPage: '/' + getT(translations, "redirect") + "/" + params + "/",

        casinoGroupLanding:
          localeAlias  +
          "/" +
          getT(translations, "owner"),


        casinoLanguageLanding:
          localeAlias  +
          "/" +
          getT(translations, "language"),

        casinoLanguage:
          localeAlias  +
          "/" +
          getT(translations, "language") +
          "/" +
          params +
          "/",

        casinoGroup:
          localeAlias  +
          "/" +
          getT(translations, "owner") +
          "/" +
          params +
          "/",

        // banking/X

        bankingLanding:
          localeAlias  + "/" + getT(translations, "banking-provider-landing") + "/",
        bankingProviderLanding:
          localeAlias  + "/" + getT(translations, "banking-provider-landing") + "/",
        // banking/X
        depositMethodLanding:
          localeAlias  +
          "/" +
          getT(translations, "banking-provider-landing") +
          "/",

        withdrawalMethodLanding:
          localeAlias  +
          "/" +
          getT(translations, "banking-provider-landing") +
          "/",

        depositMethods:
          localeAlias  +
          "/" +
          getT(translations, "banking-provider-landing") +
          "/" +
          params +
          "/",

        bankingItem:
          localeAlias  +
          "/" +
          getT(translations, "banking-provider-landing") +
          "/" +
          params +
          "/",

        withdrawalMethods:
          localeAlias  +
          "/" +
          getT(translations, "banking-provider-landing") +
          "/" +
          params +
          "/",

        // /casino/X
        // /casino/promotions
        promotionsLanding:
          localeAlias  +
          "/" +
          getT(translations, "promoted-casinos-landing") +
          "/",

        minimumDepositLanding:
          localeAlias  +
          "/" +
          getT(translations, 'minimum-deposit-landing') +
          "/",


        newCasinosLanding:
          localeAlias  +
          "/" +
          getT(translations, "new-casinos-landing") +
          "/",

        // /casino/blacklisted/X
        blacklistedCasinoLanding:
          localeAlias  +
          "/" +
          getT(translations, "blacklisted-casinos-landing") +
          "/",
        blacklistedCasino:
          localeAlias  +
          "/" +
          getT(translations, "blacklisted-casinos-landing") +
          "/" +
          params +
          "/",

        // casino/withdrawaltimes/ .. X
        withdrawalTimesLanding:
          localeAlias  +
          "/" +
          getT(translations, "withdrawal-times") +
          "/",

        hasEWallet:
          localeAlias  +
          "/" +
          getT(translations, "e-wallet") +
          "/",

        licenceLanding:
          localeAlias +
          "/" +
          getT(translations, 'licences') +
          "/",

        withdrawalTimes:
          localeAlias  +
          "/" +
          getT(translations, "withdrawal-times") +
          "/" +
          params +
          "/",

        casinoEstablished:
          localeAlias  +
          "/" +
          getT(translations, "new-casinos-landing") +
          "/" + "#" + 'casinos_' + params,

        /*
        also this will inevitably come back.
        locale +
          "/" +
          getT(translations, "casinos") +
          "/" +
          getT(translations, "established-times") +
          "-" +
          params +
          "-" +
          getT(translations, "casinos") +
          "/",
*/
        licence:
          localeAlias  +
          "/" +
          getT(translations, "licences") +
          "/" +
          params +
          "/",

        casinoReview:
          localeAlias  +
          "/" +
          params +
          "/",

        gameProvider:
          localeAlias  +
          "/" +
          getT(translations, "game-provider") +
          "/" +
          params +
          "/",

        platform:
          localeAlias +
          "/" +
          getT(translations, "platforms") +
          "/" +
          params +
          "/",

        media:
          localeAlias +
          "/" +
          getT(translations, "big-wins") +
          "/" +
          params +
          "/",

        noSticky:
          localeAlias  +
          "/" +
          getT(translations, "no-sticky") +
          "/",

        depositTimes:
          localeAlias  +
          "/" +
          getT(translations, "deposit-times") +
          "/" +
          params +
          "/",

        mobileFriendly:
          localeAlias  +
          "/" +
          getT(translations, "mobile-friendly") +
          "/",

        gamification:
          localeAlias  +
          "/" +
          getT(translations, "gamification") +
          "/",

        liveCasino:
          localeAlias  +
          "/" +
          getT(translations, "liveCasino") +
          "/",

        hasNoAccount:
          localeAlias  +
          "/" +
          getT(translations, "no-account-url") +
          "/",

        isExclusive:
          localeAlias  +
          "/" +
          getT(translations, "isexclusive") +
          "/",

        hasLiveChat:
          localeAlias  +
          "/" +
          getT(translations, "has-livechat") +
          "/",

        taxExempt:
          localeAlias  +
          "/" +
          getT(translations, "tax-exempt") +
          "/",

        hasBingo:
          localeAlias  +
          "/" +
          getT(translations, "has-bingo") +
          "/",

        hasBaccarat:
          localeAlias  +
          "/" +
          getT(translations, "has-baccarat") +
          "/",

        hasCraps:
          localeAlias  +
          "/" +
          getT(translations, "has-craps") +
          "/",

        hasBlackjack:
          localeAlias  +
          "/" +
          getT(translations, "has-blackjack") +
          "/",
        hasKeno:
          localeAlias  +
          "/" +
          getT(translations, "has-keno") +
          "/",
       hasRoulette:
         localeAlias  +
          "/" +
          getT(translations, "has-roulette") +
          "/",
        hasScratchcards:
          localeAlias  +
          "/" +
          getT(translations, "has-scratchcards") +
          "/",
        hasVideoPoker:
          localeAlias  +
          "/" +
          getT(translations, "has-videopoker") +
          "/",

        hasSportsbook:
          localeAlias  +
          "/" +
          getT(translations, "casino-sportsbook-url") +
          "/",

        hasVipProgram:
          localeAlias  +
          "/" +
          getT(translations, "has-vip-program") +
          "/",


        hasLotto:
          localeAlias  +
          "/" +
          getT(translations, "has-lotto") +
          "/",
        // /casino/bonus/
        // note - need bonus page listings

        percentBonus:
          localeAlias  +
          "/" +
          getT(translations, "bonus-silo-url") +
          "/",

        raffle:
          localeAlias  +
          "/" +
          getT(translations, "raffle") +
          "/",

        depositBonusLanding:
          localeAlias  +
          "/" +
          getT(translations, "deposit-bonus") +
          "/",

        bonusLanding:
          localeAlias  +
          "/" +
          getT(translations, "casino-bonus-landing") +
          "/",

        bonusTypes: getBonusTypeUrl(params, locale, translations),

        // slotLanding: locale + "/" + getT(translations, "slots") + "/",

        slotBonus:
          localeAlias  + "/"
          +  params + '-' + getT(translations, "freespins-slot")
          + "/",

        twitch: localeAlias  + "/" + getT(translations, "twitch") + "/",

      }
      let res = data[routeName]
      if (!_.isNotNullOrEmpty(res)) res = "/"

      if (res[res.length - 1] !== "/" && !hasHash) res = res + "/"

      return res.toLowerCase()
    }

    return "/404"
  }
}

export default GetRoute
