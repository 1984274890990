/* see: https//gastbyjs.org/tutorial/authentication-tutorial */
export const isBrowser = () => typeof window !== "undefined"
export const getUser = () => {
    const user = isBrowser() ? window.localStorage.getItem("oauth") : null;
    if (!user) return {}

    return isBrowser() && window.localStorage.getItem("oauth")
    ? JSON.parse(window.localStorage.getItem("oauth"))
    : {}
}
export const setUser = user => {
  if (isBrowser()) {
    window.localStorage.setItem("oauth", JSON.stringify(user))
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.email
}
export const logout = callback => {
  setUser({})
  callback()
}